@font-face {
  font-family: felix-titling;
  src:  url(https://www.fonts.com/font/monotype/felix-titling?QueryFontType=Web&src=GoogleWebFonts);
}

.App {
  text-align: center;
}

body {
  text-align: center;
  background: #292e3a;
  color: white;
}

.tb {
  text-align: right;
}

.title {
  font-family: 'felix-titling';
  font-size: 2.7em;
  margin-bottom: 2.5em;
  margin-top: 0;
}

.welcomeTitle {
  font-weight: 500;
  margin-top: 0;
  letter-spacing: 0.4em;
  background: -webkit-linear-gradient(#fb5950, #931468);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.roadmapTitleContainer {
  font-family: 'felix-titling';
  font-size: 2.2em;
  margin-bottom: 2.5em;
  margin-top: 2em;
  margin-top: 0;
}

.roadmapTitle {
  font-weight: 500;
  margin-top: 2em;
  letter-spacing: 0.4em;
  background: -webkit-linear-gradient(#fb5950, #931468);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.desc {
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 2.5em;
  border-style: solid;
  padding: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

.logo {
  max-height: 100em;
  width: auto;
  float: right;
}

.container {
  display: grid;
  grid-template-columns: 1 fr;
  grid-template-rows: 1 fr;
  text-align: center;
  justify-items: center;
}

.card {
  font-family: 'felix-titling';
  color: black;
  width: 20em;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; 
  background: transparent;
  border: none;
  outline: none;
  position: relative;
  margin: 2.5em;
}

.cardx {
  font-family: 'felix-titling';
  color: black;
  width: 20em;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; 
  background: transparent;
  border: none;
  outline: none;
  position: relative;
  margin: 2.5em;
}

.card:before {
    content: '';
    background: linear-gradient(45deg, #fb5950, #fb3b58, #f11a61, #c01463, #931468, #c01463, #f11a61, #fb3b58, #fb5950);
    position: absolute;
    top: -6px;
    left:-6px;
    background-size: 400%;
    z-index: -1;
    filter: blur(7px);
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    opacity: 1;
}

.cardx:before {
    content: '';
    background: linear-gradient(45deg, #fb5950, #fb3b58, #f11a61, #c01463, #931468, #c01463, #f11a61, #fb3b58, #fb5950);
    position: absolute;
    top: -6px;
    left:-6px;
    background-size: 400%;
    z-index: -1;
    filter: blur(7px);
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    opacity: 1;
}

.card:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.75);
    left: 0;
    top: 0;
    border-radius: 10px; 
}

.cardx:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    left: 0;
    top: 0;
    border-radius: 10px; 
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.card:hover {
  transform: scale(1.01);
}

.cardx:hover {
  transform: scale(1.05);
}

.container {
  padding: 2px 16px;
}

img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}

button {
  margin-left: 12px;
  margin-bottom: 12px;
  border-style: solid;
  background: transparent;
  border-color: black;
}

button:hover {
  transform: scale(1.05);
}

.cardTitle {
  margin-top: 20px;
  margin-bottom: 0;
  letter-spacing: 0.25em;
  font-size: 2.8em;
}

.info {
  font-size: 1.6em;
  margin: 10px;
  margin-top: 0px;
}

.buyFrom{
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.descp {
  font-size: 1.3em;
  margin-left: 2em;
  margin-right: 2em;
}

.buyForm {
  background: transparent;
}

.mintButton {
  font-family: 'felix-titling';
  width: 6rem;
  height: 2.5rem;
  border-radius: 2em;
  border-width: 2px;
  font-size: 1.3em;
  font-weight: bolder;
}

.mintButton:hover {
  transform: scale(1.05);
  color: #fb3b58;
  border-color: #fb3b58;
  cursor: pointer;
}

.input {
  background: transparent;
  border-color: black;
  color: black;
  width: 6em;
  height: 2em;
  border-width: 2px;
}

.glow-on-hover {
    width: 8.5rem;
    height: 2.8rem;
    border: none;
    outline: none;
    color: #fb5950;
    letter-spacing: 0.1em;
    background: transparent;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 6em;
    font-family: 'felix-titling';
    font-size: 1.2em;
    margin-top: 4px;
    margin-right: 8px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 2em;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    left: 0;
    top: 0;
    border-radius: 2em;
    border-width: 2px;
    border-color: #fb5950;
    border-style: solid;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.roadmapElements {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.burnPrestige {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
}

.rmt {
  font-family: 'felix-titling';
  letter-spacing: .2em;
  font-size: 2.3em;
}

.rmd {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.3em;
  margin-left: 1em;
  margin-right: 1em;
}

.sic {
  margin-right: 1em;
  margin-left: 1em;
  margin-bottom: 5px;
}

.aboutTitleContainer {
  font-family: 'felix-titling';
  font-size: 2.2em;
  margin-bottom: 2.5em;
  margin-top: 2em;
  margin-top: 0;
}

.aboutTitle {
  font-weight: 500;
  margin-top: 2em;
  letter-spacing: 0.4em;
  background: -webkit-linear-gradient(#fb5950, #931468);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
}

.about {
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 2.5em;
  margin-bottom: 3em;
  border-style: solid;
  padding: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

.apiLink {
  color: white;
}

.OSlink {
  color: black;
}

.featureButton {
  font-family: 'felix-titling';
  width: 8rem;
  height: 3.5rem;
  border-radius: 2em;
  border-width: 2px;
  font-size: 1.3em;
  font-weight: bolder;
  margin-bottom: 2rem;
}

.featureButton:hover {
  transform: scale(1.05);
  color: #fb3b58;
  border-color: #fb3b58;
  cursor: pointer;
}